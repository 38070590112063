
declare global {
  interface Window {
    gapi: any
  }
}

import {
  ExternalResponse,
  LoginResultSessionVM,
  LoginVM,
  SelectVM,
  UserVM
} from '@/models'
import { Component, Vue } from 'vue-property-decorator'
import { login, loginWithGoogle } from '@/components/admin/users/users.service'
import DeviceDetector from 'device-detector-js'
// import { ValidationObserver } from 'vee-validate'constt
import signalRService from '@/signalr.service'
import { namespace } from 'vuex-class'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import { AxiosResponse } from 'axios'
import SignalRService from '@/SignalRService'
import moment from 'moment'

const AppModule = namespace('app')
@Component({
  directives: {
    GoogleSignInButton
  }
})
export default class SignUp extends Vue {
  @AppModule.Getter
  public _isLoading!: () => boolean
  public show2FA = false
  public hint = null
  public form = {} as UserVM
  public genders: SelectVM[] = []
  public formatted = ''
  public selectedGender: string | null = null
  public path = ''
  public billingSoftware: [
    { text: 'SecureClaim'; value: 'orange' },
    { text: 'ProClaim'; value: 'apple' },
    { text: 'Other'; value: 'pineapple' }
  ]
  public selectedSoftware: []
  public electronicRecord: [
    { text: 'Prognosis'; value: 'orange' },
    { text: 'Practice fusion'; value: 'apple' },
    { text: 'EHR-Ez'; value: 'pineapple' },
    { text: 'NEOMED'; value: 'pineapple' },
    { text: 'Amazing Charts'; value: 'pineapple' },
    { text: 'Assertus'; value: 'pineapple' },
    { text: 'Medicrec'; value: 'pineapple' },
    { text: 'Other'; value: 'pineapple' }
  ]
  public selectedRecord: []

  public onInput(ctx) {
    const a = moment(ctx).toDate()
    if (a.toString() != 'Invalid Date') this.form.dob = a
  }
  public onContext(ctx) {
    this.formatted = moment(ctx).format('MM/DD/YYYY')
  }
  goBack() {
    this.show2FA = false
  }

  async SignInWithMicrosoft() {
    try {
      const authResponse = await this.$msal.loginPopup()
      const response = {
        token: authResponse.account.username,
        account: 'Microsoft'
      } as ExternalResponse
      const externalLoginUser = await loginWithGoogle(response)

      await this._login(externalLoginUser, true)
    } catch (err) {
      console.error(err)
    }
  }
  async getToken() {
    try {
      const authResponse = await this.$msal.acquireToken({
        scopes: ['user.read']
      })

      return authResponse.accessToken
    } catch (err) {
      console.error(err)
      return null
    }
  }

  async OnGoogleAuthSuccess(user) {
    const response = { token: user, account: 'Google' } as ExternalResponse
    const externalLoginUser = await loginWithGoogle(response)
    await this._login(externalLoginUser, true)
    // this.externalLogin = true
  }
  OnGoogleAuthFail(user) {
    console.log(user)
  }
  async _login(
    externalLoginUser: AxiosResponse<LoginResultSessionVM> = null,
    externalLogin = false
  ) {
    const nAgt = navigator.userAgent

    const deviceDetector = new DeviceDetector()
    const userAgent = nAgt
    const device = deviceDetector.parse(userAgent)
    let response = null
    if (this.show2FA) {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }
    }
    if (this.form.trustBrowser) {
      const session = btoa(
        this.form.userName +
          this.form.password +
          device.client.name +
          device.client.version +
          device.device.brand +
          device.device.type +
          device.os.name
      )
      const user = {
        session: session
      }

      this.$cookies.set('tb', user, '30d') //return this
    }
    if (!externalLogin) {
      response = await login(this.form)
    } else {
      response = externalLoginUser
    }

    if (
      response.data.is2FA &&
      this.$cookies.get('tb')?.session ==
        btoa(
          response.data.userName +
            this.form.password +
            device.client.name +
            device.client.version +
            device.device.brand +
            device.device.type +
            device.os.name
        )
    ) {
      if (response.data.token) {
        localStorage.setItem('currentUser', JSON.stringify(response.data))
        localStorage.setItem('token', response.data.token)
        localStorage.setItem(
          'needsADoctor',
          response.data.needsADoctor ? 'true' : 'false'
        )
        // await this.fetchNotifications()
        this.$router.push('/')

        return
      }
      return
    } else if (response.data.is2FA) {
      this.show2FA = true
      this.hint = response.data.hint

      return
    }

    if (response.data.token) {
      localStorage.setItem('currentUser', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      localStorage.setItem(
        'needsADoctor',
        response.data.needsADoctor ? 'true' : 'false'
      )

      // await this.fetchNotifications()
      this.$router.push('/')
    }
  }
}
